import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-pagination" }
const _hoisted_2 = { class: "grid-pagination__count-page" }
const _hoisted_3 = { class: "grid-pagination__count-page-select" }
const _hoisted_4 = { class: "grid-pagination__paginator" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_ElPagination = _resolveComponent("ElPagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "grid-pagination__count-page-label" }, "Строк на странице:", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BsSingleSelect, {
          modelValue: _ctx.curSize,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.curSize) = $event)),
          options: _ctx.sizeList,
          onChange: _ctx.changeSize
        }, null, 8 /* PROPS */, ["modelValue", "options", "onChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ElPagination, {
        "current-page": _ctx.curPage,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => ((_ctx.curPage) = $event)),
        "page-size": Number(_ctx.curSize),
        small: false,
        disabled: false,
        background: false,
        layout: "prev, pager, next",
        total: _ctx.total,
        onSizeChange: _cache[2] || (_cache[2] = () => {}),
        onCurrentChange: _ctx.currentChange
      }, null, 8 /* PROPS */, ["current-page", "page-size", "total", "onCurrentChange"])
    ])
  ]))
}