import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table-grid__cell" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_TgthAllCheckSettings = _resolveComponent("TgthAllCheckSettings")
  const _component_TableGridTh = _resolveComponent("TableGridTh")

  return (_openBlock(), _createBlock(_component_TableGridTh, {
    class: _normalizeClass(["tg-th-all-check table-grid__th th-col-check th-fixed-left", { '--is-filled': _ctx.modelSettingsValue=='check-all-page'}]),
    colData: { key: 'check', fixed: true },
    rowspan: _ctx.rowspan,
    "data-col-key": "check",
    onClick: _ctx.clickTh
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.checkedAllPage)
          ? (_openBlock(), _createBlock(_component_TgthAllCheckSettings, {
              key: 0,
              ref: "refCheckSettings",
              modelValue: _ctx.modelSettingsValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.modelSettingsValue) = $event)),
              onChange: _ctx.changeCheckSettings
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BsCheckbox, {
                  modelValue: _ctx.isCheck,
                  indeterminate: _ctx.indeterminate,
                  onChange: _ctx.changeCheckbox,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, null, 8 /* PROPS */, ["modelValue", "indeterminate", "onChange"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onChange"]))
          : (_openBlock(), _createBlock(_component_BsCheckbox, {
              key: 1,
              modelValue: _ctx.isCheck,
              indeterminate: _ctx.indeterminate,
              onChange: _ctx.changeCheckbox,
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["modelValue", "indeterminate", "onChange"]))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["rowspan", "class", "onClick"]))
}